import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from '@heroicons/react/24/outline/XMarkIcon';

export default function Modal({
  open = true,
  onClose,
  showClose = false,
  title,
  children,
  actions,
  canCloseOnClickOut = false
}) {
  function handleClose(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClose(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 bg-transparent" onClose={() => {
        if (canCloseOnClickOut) {
          handleClose()
        }
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-giveGray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-1 md:p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-2 md:px-4 pt-3 pb-2 text-left shadow-xl transition-all sm:my-8 w-full sm:w-3/4 lg:w-1/2"
              >
                <div className="relative">
                  {showClose && (
                    <div
                      className="absolute right-0 cursor-pointer z-50"
                      onClick={handleClose}
                      key="modal-close"
                    >
                      <CloseIcon className="h-5 w-5 text-giveGray-500 font-bold" />
                    </div>
                  )}
                  {!!title && (
                    <div className="my-2 text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-brandGray-900 mb-3"
                      >
                        {title}
                      </Dialog.Title>
                      <hr className="border-giveGray-200 mt-5 mb-6 -mx-6" />
                    </div>
                  )}
                  {children}
                </div>
                {!!actions && <div className="mt-3 sm:mt-3">{actions}</div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
