export function Button({
  as = 'button',
  type = 'button',
  variant = 'default',
  size = 'md',
  width = 'w-full',
  className,
  children,
  ...props
}) {
  let As = as;
  let compiledClassName = [
    'flex font-medium rounded-lg text-center items-center justify-center',
    width
  ];

  let disabled = !!props.disabled;

  switch (variant) {
    case 'info':
      compiledClassName.push(
        'bg-cyan-500 hover:bg-cyan-400 text-white focus:border-cyan-500'
      );
      break;
    case 'warning':
      compiledClassName.push(
        'bg-amber-500 hover:bg-amber-400 text-white focus:border-amber-500'
      );
      break;
    case 'secondary':
      compiledClassName.push(
        'bg-white text-giveGray-800 border-giveGray-300 border-solid border hover:bg-giveGray-50 focus:border-giveGray-500'
      );
      break;
    case 'link':
      compiledClassName = [
        'bg-transparent text-mint-600 cursor-pointer whitespace-nowrap',
      ];
      break;
    case 'gray-secondary':
      compiledClassName.push(
        'bg-giveGray-50 text-black border-giveGray-300 border-solid border hover:bg-giveGray-100'
      );
      break;
    default:
      compiledClassName.push(
        'bg-brand-200 hover:bg-brand-100 text-brandGray-900 focus:border-brand-200'
      );
      break;
  }

  switch (size) {
    case 'xs':
      compiledClassName.push('px-2 py-1 text-xs');
      break;
    case 'sm':
      compiledClassName.push('px-3 py-2 text-sm');
      break;
    case 'md':
      compiledClassName.push('px-4 py-2 text-base');
      break;
    case 'lg':
      compiledClassName.push('px-5 py-4 text-lg');
      break;
    case 'xl':
      compiledClassName.push('px-5 py-4 text-xl');
      break;
    case '2xl':
      compiledClassName.push('px-5 py-4 text-2xl');
      break;
    case '3xl':
      compiledClassName.push('px-5 py-4 text-3xl');
      break;
  }

  if (disabled) {
    compiledClassName.push('opacity-50');
  } else {
    compiledClassName.push('cursor-pointer');
  }

  compiledClassName.push(className);

  return (
    <As className={compiledClassName.join(' ')} {...props}>
      {children}
    </As>
  );
}
