import React, { useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export default function FormGroup({
  label,
  type = 'text',
  id,
  name,
  value,
  onChange,
  format,
  placeholder,
  disabled = false,
  helper = null,
  errorMessage = null,
  onRefChange = () => {},
  className,
  inputProps = {},
  labelProps = {},
  isRequired = false,
  ...props
}) {
  className = 'flex flex-col flex-1' + ' ' + className;

  let inputRefHandle = useRef();

  const handleRefChange = (ref) => {
    inputRefHandle.current = ref;
    onRefChange(ref);
  };

  const formatValue = (inputValueRaw) => {
    let tmpFormat = format;
    if (!format) {
      if (type === 'tel') {
        tmpFormat = '(000) 000-0000';
      }
    }

    let derivedValue = inputValueRaw || '';
    if (derivedValue.length > 0) {
      if (type === 'tel') {
        if (derivedValue.substring(0, 2) === '+1') {
          derivedValue = derivedValue.substring(2);
        }
        derivedValue = derivedValue.replace(/\D/g, '');
        if (derivedValue.length > 10) {
          derivedValue = derivedValue.substring(0, 10);
        }
      }

      let formattedValue = '';
      let pickedIndex = 0;
      for (let i = 0; i < tmpFormat.length; i++) {
        if (derivedValue.length > pickedIndex) {
          if (tmpFormat[i] === '0') {
            if (derivedValue[pickedIndex].replace(/\D/g, '')) {
              formattedValue += derivedValue[pickedIndex];
            }
            pickedIndex++;
          } else if (tmpFormat[i] === 'A') {
            if (derivedValue[pickedIndex].replace(/[^A-Z]/g, '')) {
              formattedValue += derivedValue[pickedIndex];
            }
            pickedIndex++;
          } else if (tmpFormat[i] === 'a') {
            if (derivedValue[pickedIndex].replace(/[^a-z]/g, '')) {
              formattedValue += derivedValue[pickedIndex];
            }
            pickedIndex++;
          } else if (tmpFormat[i] === 'i') {
            if (derivedValue[pickedIndex].replace(/[^A-z]/g, '')) {
              formattedValue += derivedValue[pickedIndex];
            }
            pickedIndex++;
          } else if (tmpFormat[i] === derivedValue[pickedIndex]) {
            formattedValue += derivedValue[pickedIndex];
            pickedIndex++;
          } else {
            formattedValue += tmpFormat[i];
          }
        } else {
          break;
        }
      }
      derivedValue = formattedValue;
    }

    return derivedValue;
  };

  const handleChange = (e) => {
    if (e.target.type === 'tel') {
      let originalStart = e.target.selectionStart;
      let originalEnd = e.target.selectionEnd;
      let originalLength = e.target.value.length;
      e.target.value = formatValue(e.target.value);
      if (e.target.value.length <= originalLength) {
        e.target.selectionStart = originalStart;
        e.target.selectionEnd = originalEnd;
      }
    }

    if (onChange) {
      onChange(e);
    }
  };

  if (type === 'tel' || format) {
    value = formatValue(value);
  }

  let { className: inputPropsClassName, ...remainingInputProps } = inputProps;

  let inputClassName = `flex-1 p-3 rounded-lg ${
    isRequired ? 'border-2' : 'border-1'
  } border-${
    isRequired ? 'giveRed' : 'giveGray'
  }-300 border-solid focus:border-mint-600 focus:ring focus:ring-mint-600 focus:ring-opacity-25`;

  if (disabled) {
    inputClassName.replace(/red/g, 'gray');
    inputClassName += ' bg-giveGray-100 text-giveGray-500';
  }

  if (inputPropsClassName) {
    inputClassName += ' ' + inputPropsClassName;
  }

  let renderedErrorMessage = '';
  let errorIndicator = '';
  if (errorMessage) {
    inputClassName += ' border-mint-600';
    renderedErrorMessage = (
      <p className="mt-2 text-sm text-giveRed-800" key="error-message">
        {errorMessage}
      </p>
    );

    errorIndicator = (
      <div
        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        key="error-indicator"
      >
        <ExclamationCircleIcon className="h-5 w-5 text-giveRed-800" aria-hidden="true" />
      </div>
    );
  }

  let inputComponent = null;
  if (type === 'textarea') {
    inputComponent = (
      <textarea
        id={id}
        ref={handleRefChange}
        name={name}
        disabled={disabled}
        className={inputClassName}
        value={value}
        onChange={handleChange}
        {...remainingInputProps}
      />
    );
  } else {
    inputComponent = (
      <input
        id={id}
        type={type}
        ref={handleRefChange}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        className={inputClassName}
        value={value}
        onChange={handleChange}
        {...remainingInputProps}
      />
    );
  }

  return (
    <div className={className} {...props}>
      <label className="font-semibold mb-1" htmlFor={id} {...labelProps}>
        {label}
      </label>
      {!isRequired ? (
        <div className="relative w-full flex">
        {inputComponent}
        {errorIndicator}
      </div>
      ): (
        <div className="relative w-full flex flex-col">
        {inputComponent}
        {isRequired && (
          <span className="text-mint-600">Required</span>
        )}
        {errorIndicator}
      </div>
      )
      }
      {helper}
      {renderedErrorMessage}
     
    </div>
  );
}
