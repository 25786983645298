export default function AuthLayout({ children }) {
  return (
    <div className="w-full h-screen flex">
      <div className="flex-1">
        <div className="py-6 px-12 sm:py-9 sm:px-14 md:py-12 md:px-18 lg:px-24 h-screen overflow-y-auto flex flex-col items-start justify-start md:items-center md:justify-center">
          <a href="https://www.lemonaide.co/" className="mb-10 md:hidden">
            <img src="/images/lemonaide-logo.svg" className="h-10" />
          </a>
          {children}
        </div>
      </div>
      <div
        className="hidden md:flex md:flex-1"
        style={{
          background: 'url(/images/lemonaide-auth-bg.png)',
          backgroundSize: 'cover',
          backgroundRepeat: 'none',
          backgroundPosition: 'center',
        }}
      >
        {/* <div className="flex flex-col justify-center sm:px-14 md:px-18 lg:px-24">
          <div>
            <a href="/" className="bg-transparent mb-7">
            <img src="/images/lemonaide-logo-white-green.png" style={{ height: '40px' }} />
          </a>
            <h1 className="text-white font-semibold text-4xl mb-5">Experience donating with 100% transparency.</h1>
            <p className="text-white font-extralight whitespace-normal">
              Lemonaide uses the Give blockchain to guarantee each donation is trackable and visible to all.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
