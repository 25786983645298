import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@nerdcoresdk/nerd-core-ui';
import LoginForm from '~src/components/Auth/LoginForm';

import { brand } from '~src/brand/brand';
import { config } from '~config';
import { routes } from '~src/routes/routes';
import { useAuth } from '~src/hooks/useAuth';
import {browserLocalPersistence} from 'firebase/auth';
import { Alert, TwoFactorCode } from '~src/components/common';
import Modal from '~src/components/Modal';

import AuthLayout from '~src/components/Layout/AuthLayout';
import { sign } from 'crypto';

const captchaId = 'recaptchaDiv';

export default function Login() {
  const router = useRouter();
  const page = brand.pages.login;
  const {
    firebaseAuth,
    createRecaptcha,
    enrichToken,
    sendVerificationEmail,
    sendLoginMFA,
    signin,
    validateMFACode,
  } = useAuth();
  const [error, setError] = useState({});
  const [message, setMessage] = useState(null);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMFA, setIsMFA] = useState(false);
  const [mfaError, setMfaError] = useState({});
  const [phone, setPhone] = useState('');
  const [recaptchaData, setRecaptchaData] = useState({});
  const [sessionData, setSessionData] = useState();
  const [verificationId, setVerificationId] = useState();

  useEffect(() => {
    createRecaptcha(captchaId).then((recaptchaVerifier) => {
      setRecaptchaData(recaptchaVerifier);
    });
  }, [createRecaptcha]);

  useEffect(() => {
    let signInMessage = localStorage.getItem("sign-in-message");
    if (signInMessage) {
      let decoded = JSON.parse(signInMessage);
      if (decoded && decoded.message) {
        setMessage({
          type: decoded.type ?? "info",
          message: decoded.message
        });
      }
    }
  }, []);

  const handleDidNotReceiveEmail = useCallback(() => {
    const email = localStorage.getItem('email');
    sendVerificationEmail({
      email: email,
      link: config.brand.emailConfirmationLinkUrl,
    }).then(() => alert('New email sent'));
  }, [sendVerificationEmail]);

  const buildErrorMessage = useCallback(() => {
    return (
      <div className="flex-col items-center text-mint-600 py-2 px-3 w-full">
        <div className={'text-center'} data-core-qa="loginPageNotVerifiedErrorText">
          {brand.errorMessages.emailNotVerifiedText}
        </div>
        <Button
          kind="link"
          onClick={handleDidNotReceiveEmail}
          data-core-qa="loginPageButtonNotVerified"
        >
          {brand.errorMessages.emailNotVerifiedLinkText}
        </Button>
      </div>
    );
  }, [handleDidNotReceiveEmail]);

  const handleSubmit = useCallback(
    ({ email, password }) => {
      localStorage.setItem('email', email);
      return signin({
        email,
        password,
        persistenceType: browserLocalPersistence
      })
        .then((session) => {
          //if user has 2FA enabled,text the code
          if (session.resolver) {
            console.log('MFA is enabled...');
            setSessionData(session.resolver);
            setIsMFA(true); //open modal to enter code
            setPhone(
              session.phone.replace(
                /(\W{1})(\W{1})(\W{3})(\W{3})(\d{2})(\d{2})/,
                '$1 x (xxx) xxx-xx$6'
              )
            );
            sendSMS(session.resolver, recaptchaData);
          } else {
            console.log('2FA disabled...');
            //no 2FA user

            let signInMessage = localStorage.getItem("sign-in-message");
            if (signInMessage) {
              localStorage.removeItem("sign-in-message");
            }

            let postLoginRoute = localStorage.getItem('post_login_redirect');
            if (postLoginRoute) {
              localStorage.removeItem('post_login_redirect');
              router.push(postLoginRoute);
            } else {
              router.replace({
                pathname: routes.agreements.path,
                query: { login: true },
              });
            }
          }
        })
        .catch((err) => {
          //email & password failed errors
          console.warn("Failed regular login...", err);
          const message = err.error?.message || err.message;
          if (message === 'Email not verified') {
            setError({ message: buildErrorMessage() });
          } else if (message.includes('wrong-password')) {
            setInvalidPassword(true);
          } else if (message.includes("user-not-found")) {
            setError({message: "Authentication failed."});
          } else if (message.includes('disabled')) {
            setError({ message: brand.errorMessages.disabledAccount });
          } else {
            setError({ message: brand.errorMessages.genericError });
          }
        });
    },
    [signin, router, buildErrorMessage, sendSMS, recaptchaData]
  );

  const sendSMS = useCallback(
    (session, recaptchaData) => {
      sendLoginMFA({
        sessionData: session,
        recaptchaVerifier: recaptchaData,
      })
        .then((verificationId) => {
          setVerificationId(verificationId);
        })
        .catch((error) => {
          setMfaError({ error: true, message: brand.errorMessages.genericError });
        });
    },
    [sendLoginMFA]
  );

  const handleVerificationCode = useCallback(
    (code) => {
      setIsLoading(true);
      //validate the user code
      return validateMFACode({
        verificationId,
        verificationCode: code,
        sessionData: sessionData,
      })
        .then((data) => {
          //finish the login process with backend to enrich the user token
          enrichToken({ token: data.user.accessToken }).then(() => {

            let signInMessage = localStorage.getItem("sign-in-message");
            if (signInMessage) {
              localStorage.removeItem("sign-in-message");
            }

            let postLoginRoute = localStorage.getItem('post_login_redirect');
            if (postLoginRoute) {
              localStorage.removeItem('post_login_redirect');
              router.push(postLoginRoute);
            } else {
              router.replace(routes.agreements.path);
            }
            setIsLoading(false);
          });
        })
        .catch((error) => {
          console.warn("Caught Error: ", error);
          setIsLoading(false);
          if (error.message.includes('TOO_MANY_ATTEMPTS')) {
            setError({
              error: true,
              message: brand.pages.manageAccount.mfa.errorMessages.tooManyTries,
            });
          } else if (error.code === 'auth/invalid-verification-code') {
            setMfaError({
              error: true,
              message: brand.pages.manageAccount.mfa.errorMessages.invalidCode,
            });
          } else if (error.message === "EMAIL_NOT_FOUND" || error.message === "INVALID_PASSWORD") {
            setError({
              error: true,
              message: "Authentication failed."
            })
          } else {
            setMfaError({ error: true, message: brand.errorMessages.genericError });
          }
        });
    },
    [validateMFACode, verificationId, sessionData, enrichToken, router]
  );

  return (
    <AuthLayout>
      <section className="">
        {/* recaptcha verifier div is required and must be empty - do not remove*/}
        <div id={captchaId} data-core-qa="loginRecaptcha"></div>
        <div className="">
          {!!message && <Alert key={"sign-in-message"} type={message.type}>{message.message}</Alert>}
          <LoginForm
            // Form can display round brand icon by uncommenting this prop
            handleSubmitCallback={handleSubmit}
            invalidPassword={invalidPassword}
            clearInvalidPasswordCallback={() => setInvalidPassword(false)}
          />
          <div
            className="flex-col items-center text-mint-600 py-2 px-3 w-full"
            data-core-qa="loginPageErrorText"
          >
            {error.message && error.message}
          </div>
        </div>
        <p
          className="mt-1 text-base underline-offset-1 text-giveGray-700"
          data-core-qa="loginPageNeedHelpText"
        >
          {brand.pages.login.supportText}{' '}
          <span
            className={'cursor-pointer underline'}
            data-core-qa="loginPageNeedHelpLinkText"
            onClick={() => router.push(routes.support.path)}
          >
            {brand.pages.login.supportLinkText}
          </span>
        </p>
        {isMFA && (
          <Modal showClose={true} onClose={() => setIsMFA(false)}>
            <section
              className={
                'flex flex-col justify-center items-center rounded-md px-2 py-3 text-center'
              }
            >
              <h2
                className={'text-xl font-bold mb-3'}
                data-core-qa="loginMFAModalTitleText"
              >
                {page.mfaModal.header}
              </h2>
              <p
                className={'text-base font-normal mb-2'}
                data-core-qa="loginMFAModalSubText"
              >
                {page.mfaModal.subText} {phone}.
              </p>
              {mfaError.error && (
                <Alert type="error" data-core-qa="loginMFAModalErrorText">
                  {page.mfaModal.invalidCodeError}
                </Alert>
              )}
              <TwoFactorCode
                handleSubmit={handleVerificationCode}
                handleCancel={() => setIsMFA(false)}
                error={mfaError}
                setError={setMfaError}
                handleResend={() => sendSMS(sessionData, recaptchaData)}
                isLoading={isLoading}
              />
              <p
                className={'text-sm font-normal mt-4'}
                data-core-qa="loginMFAModalSupportText"
              >
                {page.mfaModal.supportText}
              </p>
            </section>
          </Modal>
        )}
      </section>
    </AuthLayout>
  );
}

Login.strictlyPublicRoute = true;
