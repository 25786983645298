import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormGroup from '../Form/FormGroup';
import { Button } from '../Button';

const initialFormState = {
  email: { value: '', error: false, message: '' },
  password: { value: '', error: false, message: '' },
};

const errorMessage = 'This field is required';

export default function LoginForm({
  invalidPassword,
  clearInvalidPasswordCallback,
  handleSubmitCallback,
  ...rest
}) {
  const [formState, setFormState] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (invalidPassword) {
        console.log("Setting invalid password...");
        setFormState((prevState) => ({
          ...prevState,
          password: {
            ...prevState.password,
            error: true,
            message: 'Incorrect Password',
          },
        }));
      } else {
        console.log("Clearing invalid password...");
        setFormState((prevState) => ({
          ...prevState,
          password: {
            ...prevState.password,
            error: false,
            message: '',
          },
        }));
      }
    }
  }, [invalidPassword, isMounted]);

  const isSubmitDisabled = useMemo(() => {
    const hasErrors = Object.values(formState).filter(
      (state) => state.error === true || state.value === '' || isLoading
    );

    return !!hasErrors.length;
  }, [formState, isLoading]);

  const handleInputChange = useCallback(
    ({ target: { value, id } }) => {
      setFormState((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value,
        },
      }));

      clearInvalidPasswordCallback();
    },
    [setFormState]
  );

  const handleValidationOnBlur = useCallback(
    ({ target: { id, value } }) => {
      setFormState((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          error: !value.length,
          message: !value.length ? errorMessage : '',
        },
      }));
    },
    [formState, setFormState]
  );

  const clearValidationErrorOnFocus = useCallback(({ target: { id, value } }) => {
    setFormState((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        error: false,
        message: '',
      },
    }));
  });

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setIsLoading(true);
      return handleSubmitCallback({
        email: formState.email.value,
        password: formState.password.value,
      }).finally(() => setIsLoading(false));
    },
    [formState]
  );

  return (
    <form className="w-full" onSubmit={handleFormSubmit} {...rest}>
      <div className="mb-7">
        <h1 className="text-3xl font-bold">Log in to access your account</h1>
        <p className="mt-3 text-giveGray-500">
          Don&apos;t have a Lemonaide account?
          <Button as="a" variant="link" className="-ml-2" href="/signup">
            Create a new account
          </Button>
        </p>
      </div>

      <FormGroup
        label="Email Address"
        id="email"
        onChange={handleInputChange}
        inputProps={{
          placeholder: 'Email Address',
          onBlur: handleValidationOnBlur,
          onFocus: clearValidationErrorOnFocus,
          required: true,
        }}
        value={formState.email.value}
        errorMessage={formState.email.error ? formState.email.message : null}
        type="email"
        className="p-0 mb-5"
      />

      <FormGroup
        type="password"
        label="Password"
        inputProps={{
          placeholder: 'Password',
          onBlur: handleValidationOnBlur,
          onFocus: clearValidationErrorOnFocus,
          required: true,
        }}
        id="password"
        onChange={handleInputChange}
        value={formState.password.value}
        errorMessage={formState.password.error ? formState.password.message : null}
        className="p-0 mb-3"
      />

      <p className="float-right">
        <Button as="a" variant="link" size="sm" href="/forgot-password">
          Forgot Password?
        </Button>
      </p>

      <div className="clear-both"></div>

      <Button type="submit" disabled={isSubmitDisabled} className="mt-5 clear-both">
        {isLoading ? 'Logging in...' : 'Log In'}
      </Button>
    </form>
  );
}
